@import './_colors.scss';

h1,
h2,
h3,
h4 {
  font-style: normal;
  color: $darkGrey;
}

h1 {
  font-weight: 300;
  font-size: 28px;
  line-height: 34px;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}
