@use './colors' as colors;
@import 'react-toastify/dist/ReactToastify.css';

.Toastify__toast-container {
  width: 400px;
  z-index: 1000001;
}
.Toastify__toast-theme--colored.Toastify__toast--success,
.Toastify__toast--success {
  background: colors.$avatar2;
}
.Toastify__toast-theme--colored.Toastify__toast--warning,
.Toastify__toast--warning {
  background: colors.$avatar5;
}
.Toastify__toast-theme--colored.Toastify__toast--error,
.Toastify__toast--error {
  background: colors.$avatar1;
}
.Toastify__toast-body {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: colors.$white;
}
