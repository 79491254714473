// Defining fonts
$initial-font-size: 16px !default;

// Defining spaces
$initial-space: 16px !default;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
}

.underline-none {
  text-decoration-line: none;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: end;
}
.align-start {
  align-items: flex-start;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-100 {
  width: 100%;
}
.w-85 {
  width: 85%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

.w-auto {
  width: auto;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.grid {
  display: grid;
}

.pointer {
  cursor: pointer;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-end {
  text-align: end;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-start {
  text-align: start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.text-uppercase {
  text-transform: uppercase;
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 16px;
}

.gap-4 {
  gap: 32px;
}

// Z-index
.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}
