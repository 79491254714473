// Spacing classes
$spaces: (
  0: 0, // 0px
  1: ($initial-space * .25), // 4px
  2: ($initial-space * .5), // 8px
  3: ($initial-space * .75), // 12px
  4: $initial-space, // 16px
  5: ($initial-space * 1.25), // 20px
  6: ($initial-space * 1.5), // 24px
  7: ($initial-space * 1.75), // 28px
  8: ($initial-space * 2), // 32px
  9: ($initial-space * 2.25), // 36px
  10: ($initial-space * 2.5), // 40px
  11: ($initial-space * 2.75), // 44px
  12: ($initial-space * 3), // 48px
  13: ($initial-space * 4), // 64px
  14: ($initial-space * 4.25), // 68px
  15: ($initial-space * 4.5), // 72px
  16: ($initial-space * 4.75), // 76px
  17: ($initial-space * 5), // 80px

) !default;

@each $property, $type in (margin: custom-m, padding: custom-p) {
  @each $index, $value in $spaces {
    .#{$type}-#{$index} { #{$property}: $value !important; }
    .#{$type}t-#{$index},
    .#{$type}y-#{$index} {
      #{$property}-top: $value !important;
    }
    .#{$type}r-#{$index},
    .#{$type}x-#{$index} {
      #{$property}-right: $value !important;
    }
    .#{$type}b-#{$index},
    .#{$type}y-#{$index} {
      #{$property}-bottom: $value !important;
    }
    .#{$type}l-#{$index},
    .#{$type}x-#{$index} {
      #{$property}-left: $value !important;
    }
  }
}

@each $index, $value in $spaces {
  @if $index != 0 {
    .custom-m-n#{$index} { margin: -$value !important; }
    .custom-mt-n#{$index},
    .custom-my-n#{$index} {
      margin-top: -$value !important;
    }
    .custom-mr-n#{$index},
    .custom-mx-n#{$index} {
      margin-right: -$value !important;
    }
    .custom-mb-n#{$index},
    .custom-my-n#{$index} {
      margin-bottom: -$value !important;
    }
    .custom-ml-n#{$index},
    .custom-mx-n#{$index} {
      margin-left: -$value !important;
    }
  }
}

.custom-m-auto {
  margin: auto
}