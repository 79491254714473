// Fonts
.font-size-xxxxs {
  font-size: $initial-font-size * .625 !important; // 10px
}

.font-size-xxxs {
  font-size: $initial-font-size * .75 !important; // 12px
}

.font-size-xxs  {
  font-size: $initial-font-size * .875 !important; // 14px
}

.font-size-xs  {
  font-size: $initial-font-size !important; // 16px
}

.font-size-sm  {
  font-size: $initial-font-size * 1.25 !important; // 20px
}

.font-size-md  {
  font-size: $initial-font-size * 1.5 !important; // 24px
}

.font-size-lg  {
  font-size: $initial-font-size * 2 !important; // 32px
}

.font-size-xl  {
  font-size: $initial-font-size * 2.5 !important; // 40px
}

.font-size-xxl  {
  font-size: $initial-font-size * 3 !important; // 48px
}

.font-size-xxxl  {
  font-size: $initial-font-size * 3.5 !important; // 56px
}

.font-size-mega  {
  font-size: $initial-font-size * 4 !important; // 64px
}

.font-size-giga  {
  font-size: $initial-font-size * 4.5 !important; // 72px
}

.font-size-tera  {
  font-size: $initial-font-size * 5 !important; // 80px
}

// New fonts weight
.font-weight-300  {
  font-weight: 300 !important;
}

.font-weight-400  {
  font-weight: 400 !important;
}

.font-weight-500  {
  font-weight: 500 !important;
}

.font-weight-600  {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

// New line height
.line-height-16 {
  line-height: 16px !important;
}

.line-height-18 {
  line-height: 18px !important;
}


.line-height-22 {
  line-height: 22px !important;
}

.line-height-24 {
  line-height: 24px !important;
}

.line-height-28 {
  line-height: 28px !important;
}

.line-height-32 {
  line-height: 32px !important;
}

.line-height-34 {
  line-height: 34px !important;
}

.line-height-48 {
  line-height: 48px !important;
}