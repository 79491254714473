// Borders

.border-none {
  border: none !important;
}

.border-medium {
  border-color: $medium !important;
}

// Radius
.border-radius-0 {
  border-radius: 0px !important;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-50 {
  border-radius: 50px !important;
}
