$primary: #1f63e5;
$secondary: #003570;
$light: #eef3fa;
$medium: #92b5dd;
$darkGrey: #2b2b2b;
$gray: #aaaaaa;
$white: white;
$black: black;
$red: #e73223;
$flush-orange: #ff7a00;
$yellow: #ffb800;
$green-haze: #00ac26;
$green: #29de3b;
$green-lizard: #99eb33;
$copper: #ad6d32;
$silver-chalice: #afb0af;
$spiro-disco-ball: #28c1ff;
$avatar1: #f24e1e;
$avatar2: #0acf83;
$avatar3: #a259ff;
$avatar4: #00adf3;
$avatar5: #ff9518;
$avatar6: #f149cc;
$old-silver: #838383;
$lavender-indigo: #9760f2;

:export {
  primary: $primary;
  secondary: $secondary;
  light: $light;
  medium: $medium;
  darkGrey: $darkGrey;
  gray: $gray;
  white: $white;
  black: $black;
  red: $red;
  flushOrange: $flush-orange;
  yellow: $yellow;
  greenHaze: $green-haze;
  green: $green;
  greenLizard: $green-lizard;
  copper: $copper;
  silverChalice: $silver-chalice;
  spiroDiscoBall: $spiro-disco-ball;
  avatar1: $avatar1;
  avatar2: $avatar2;
  avatar3: $avatar3;
  avatar4: $avatar4;
  avatar5: $avatar5;
  avatar6: $avatar6;
  oldSilver: $old-silver;
  lavenderIndigo: $lavender-indigo;
}

:root {
  --primary: #1f63e5;
}

// Utility classes
.text-primary {
  color: $primary;
}

.text-black {
  color: $black;
}

.text-gray {
  color: $gray;
}

.text-white {
  color: $white;
}

// Backgrounds
.bg-primary {
  background-color: $primary;
}

.bg-white {
  background-color: $white;
}
